<template>
  <v-app class="lato-font">
    <div class="row marg-0 padd-t-86">
      <div class="col-md-7 col-sm-12 col-12 padd-0">
        <div class="">
          <div class="row marg-0 align-center">
            <div class="marg-0">
              <div>
                <span class="fsize24 marg-0 clr-000000"> 9884054321</span>
              </div>
              <span class="f-size16 clr-f74f73 padd-t-6 fw-bold"
                >Above the mobile number already registered !</span
              >
            </div>
          </div>

          <div class="marg-t-38">
            <span class="vl-middle">
              <input
                type="radio"
                id="number"
                name="mobile"
                value="num"
                checked
                class="h-18 w-18"
              />
            </span>

            <span class="marg-l-22 f-size16 fw-bold clr-000000">
              I accept to declare the same mobile number belongs to
            </span>
            <div class="marg-l-40 marg-t-10">
              <!-- <input
                type="text"
                class="w-270 h-40 bor-radius padd-8-16 border-input fsize14 box-shado"
                placeholder=""
                 checked
                autocomplete="off"
              /> -->
              <b-form-select class="mar-l-20" id="mwDDList">
                <option value="1">Self</option>
                <option value="2">Spouse</option>
                <option value="3">Father</option>
                <option value="4">Mother</option>
              </b-form-select>
            </div>
          </div>
          <div class="marg-t-36">
            <span class="vl-middle">
              <input
                type="radio"
                id="number"
                name="mobile"
                value="num"
                class="h-18 w-18"
              />
            </span>

            <span class="marg-l-22 f-size16 fw-bold clr-000000">
              I would like to add another alternative mobile number
            </span>
            <div class="marg-l-40 marg-t-10 fsize14 clr-55585a padd-l-8">
              Mobile Number
            </div>
            <div class="buttonIn marg-l-40 marg-t-10 clrplce">
              <button class="">+91</button>
              <input
                type="text"
                class="w-270 h-40 bor-radius padd-8-16 border-input fsize14 box-shado"
                placeholder="Your 10 digit mobile number"
                autocomplete="off"
              />
            </div>
            <div class="padd-t-6 marg-l-40 fsize12 clr9b9b">
              <span>You will receive an OTP on your number</span>
            </div>
          </div>
        </div>
        <div class="marg-t-40 marg-b-48">
          <router-link
            to="/registermobile"
            active-class="active"
            tag="button"
            exact
            class="btn-color fsize18 padd-0-16 bor-radius h-40"
            >Continue</router-link
          >
        </div>
      </div>
      <div class="col-md-5 col-sm-12 padd-0 hideimgmob">
        <img class="desktopimg" src="..\assets\Desktop-login.svg" alt="" />
      </div>
    </div>
  </v-app>
</template>

<script>
export default {};
</script>

<style>
.padd-t-86 {
  padding-top: 86px;
}
.clrplce input::-webkit-input-placeholder {
  color: #a1a1a1 !important;
}
</style>