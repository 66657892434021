<template>
  <v-app class="lato-font">
    <div class="row marg-0">
      <div class="col-md-8 col-sm-12 col-12  padd-r-40 padd-t-128 padd-l-0 padd-r-0-mob">
        <div class="row marg-0 align-center">
          <span class="fsize24 clr-000000">Link bank account</span>
        </div>

        <span class="fsize16-afafaf">
          Bank account in your name from which you will transact funds for
          trading.
        </span>

        <hr class="lineBelowHead marg-0 marg-t-30" />
        <div class="marg-t-24 h-48">
          <span class="fsize20 clr-000000"
            >Copy of cancelled cheque / statement</span
          >
          <span class="f-right">
            <button
              class="borderclr-0073bc clr-0073bc fsize18 padd-0-16 bor-radius h-40"
            >
              Upload
            </button></span
          >
        </div>
        <div class="marg-t-24">
          <span class="fsize14 clr9b9b"
            >Copy of cancelled cheque with your name or latest bank statement
            that shows:
          </span>
          <div class="fsize14 clr9b9b marg-t-18">
            <div class="padd-l-20">
              <span>&#8226;</span>
              <span class="fsize14 clr9b9b padd-l-12">
                Your Bank account number 156101000011779</span
              >
            </div>
            <div class="padd-l-20">
              <span>&#8226;</span>
              <span class="fsize14 clr9b9b padd-l-12">
                IFSC Code IOBA0001561</span
              >
            </div>
          </div>
          <div class="marg-t-32">
            <p class="fsize14 clr9b9b">
              Incase, either your cheque or bank statement does not have your
              name, account number, IFSC, & M(CR together, please merge both and
              upload a single file for your bank proof containing all such
              details. To know how to merge files refer to this article.
            </p>
          </div>
        </div>
        <hr class="lineBelowHead marg-0 marg-t-30" />
        <div class="marg-t-24 h-48">
          <span class="fsize20 clr-000000">Income proof (Optional)</span>
          <span class="f-right">
            <button
              class="borderclr-0073bc clr-0073bc fsize18 padd-0-16 bor-radius h-40"
            >
              Upload
            </button></span
          >
        </div>
        <div class="marg-t-24">
        
          <b-form-select class="mar-l-20 w-100per" id="mwDDList" v-model="selectedValue">
            <option value="1">Select proof type</option>
            <option value="2"></option>
          </b-form-select>
        </div>
        <div class="marg-t-32">
          <span class="fsize14 clr9b9b">
            Income proof is required if you want F&O and MCX trading. Not
            required if you just want to buy and sell equity stocks.
          </span>
        </div>
        <hr class="lineBelowHead marg-0 marg-t-30" />
        <div class="marg-t-24 h-48">
          <span class="fsize20 clr-000000">Signature</span>
          <span class="f-right">
            <button
              class="borderclr-0073bc clr-0073bc fsize18 padd-0-16 bor-radius h-40"
            >
              Upload
            </button></span
          >
        </div>
        <div class="marg-t-18 marg-b-40">
          <span class="fsize14 clr9b9b"
            >Please sign on a blank paper with a pen & upload a photo of the
            same. Signatures using pencils, sketch pens, etc., are not
            accepted.</span
          >
        </div>

        <hr class="lineBelowHead marg-0 marg-t-30" />
        <div class="marg-t-24 h-48">
          <span class="fsize20 clr-000000">Copy of PAN</span>
          <span class="f-right">
            <button
              class="borderclr-0073bc clr-0073bc fsize18 padd-0-16 bor-radius h-40"
            >
              Upload
            </button></span
          >
        </div>
        <div class="marg-t-18 marg-b-40">
          <span class="fsize14 clr9b9b"
            >Upload a signed copy of your PAN card.</span
          >
        </div>
      </div>
      <div class="col-md-4 col-sm-12  padd-0 padd-0-30 backgroundclr-efef padd-t-1-mob">
        <div class="marg-t-44 backgroundclr-fff padd-t-20 padd-l-20 padd-b-75">
          <div class="">
            <img class="profileimg" src="..\assets\Profileicon.svg" alt="" />
            <span class="fsize14 fw-bold clr-000000 margin-l-12"
              >Avinash Kumar</span
            >
          </div>
          <div class="marg-t-24 fsize14 clr-000000">
            <img class="profileimg" src="..\assets\mail-icon.svg" alt="" />
            <span class="margin-l-12">1980-09-30</span>
            <img
              class="profileimg marg-l-40"
              src="..\assets\mail-icon.svg"
              alt=""
            />
            <span class="margin-l-12">Male</span>
          </div>
          <div class="marg-t-24">
            <img class="profileimg" src="..\assets\mail-icon.svg" alt="" />
            <span class="fsize14 clr-000000 margin-l-12">
              avinash1508@gmail.com</span
            >
          </div>
          <div class="marg-t-24">
            <img class="profileimg" src="..\assets\locationicon.svg" alt="" />
            <span class="fsize14 clr-000000 margin-l-12">
              No.45, Darshan Apartments <br /><span
                class="padd-l-20 margin-l-12"
                >Gandhi Nagar, West Mambalam,</span
              ><br /><span class="padd-l-20 margin-l-12"> T.Nagar,</span>
              Chennai - 600024.</span
            >
          </div>
        </div>
        <div class="marg-t-18">
          <span class="fsize14 clr-55585a"> Your PAN </span>
        </div>
        <div class="padd-t-6">
          <span class="clr-0073bc fsize24">KKHAQ7832T</span>
        </div>
        <div class="marg-t-18 h-44">
          <span class="fsize12 clr-000000">
            This PAN should belongs to you, the applicant.<br />
            If it does not, <span class="clr-0073bc">start over</span>.
          </span>
        </div>
        <div class="marg-t-8">
          <router-link
            to="/"
            active-class="active"
            tag="button"
            exact
            class="backgroundclr-9d9d clr-fff fsize18 padd-0-16 bor-radius h-40 w-100per"
          >
            Continue</router-link
          >
          <router-link
            to="/webcam"
            active-class="active"
            tag="button"
            exact
            class="borderclr-2e3031 fsize18 padd-0-16 bor-radius h-40 marg-l-8 clr-000000 w-100per marg-l-0-mob marg-t-12-mob"
          >
            Back</router-link
          >
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      selectedValue: 1,
    };
  },
};
</script>

<style>
</style>